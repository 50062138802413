import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import Product from "components/product";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "keyboard"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/keyboard.png"}) { ...eyecatchImg },
    membrane: file(relativePath: { eq: "keymouse/membrane.PNG"}) { ...normalImg },
    pantagraph: file(relativePath: { eq: "keymouse/pantagraph.PNG"}) { ...normalImg },
    brown_s: file(relativePath: { eq: "keymouse/brown_s.png"}) { ...smallImg },
    red_s: file(relativePath: { eq: "keymouse/red_s.png"}) { ...smallImg },
    blue_s: file(relativePath: { eq: "keymouse/blue_s.png"}) { ...smallImg },
    musetti: file(relativePath: { eq: "keymouse/musetti.PNG"}) { ...normalImg },
    keyboard_ranking: file(relativePath: { eq: "keymouse/keyboard_ranking.PNG"}) { ...normalImg },
    tenkey: file(relativePath: { eq: "keymouse/tenkey.png"}) { ...normalImg },
    elgonomic: file(relativePath: { eq: "keymouse/elgonomic.PNG"}) { ...normalImg },
    separate: file(relativePath: { eq: "keymouse/separate.PNG"}) { ...normalImg },
    keypitch_s: file(relativePath: { eq: "keymouse/keypitch_s.png"}) { ...smallImg },
    keyboard_light_s: file(relativePath: { eq: "keymouse/keyboard_light_s.jpg"}) { ...smallImg },
    filco: file(relativePath: { eq: "keymouse/filco.PNG"}) { ...normalImg },
    logicool: file(relativePath: { eq: "keymouse/logicool.PNG"}) { ...normalImg },
    musetti: file(relativePath: { eq: "keymouse/musetti.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`キーボードの選び方と、おすすめのキーボード３選`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="キーボードの選び方" mdxType="Image" />
    <p>{`キーボードは好みが非常にはっきりと別れるため、`}<strong parentName="p">{`万人へおすすめできるキーボードは無い`}</strong>{`。しかし`}<strong parentName="p">{`ある程度の傾向は見て取れ、またTPOにより適したキーボードが変わってくる`}</strong>{`。よって失敗しない選び方は解説できる。`}</p>
    <p>{`本ページではまずキーボードの種類と特徴を説明し、打鍵感や音の違い、アンケートで得られた感想などを紹介する。次にテンキーの有無や無線・有線など選ぶときに気になるポイントを解説し、最後におすすめのキーボードをいくつか紹介していこう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "キーボード方式の基本２種類、メンブレンとパンタグラフ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E6%96%B9%E5%BC%8F%E3%81%AE%E5%9F%BA%E6%9C%AC%EF%BC%92%E7%A8%AE%E9%A1%9E%E3%80%81%E3%83%A1%E3%83%B3%E3%83%96%E3%83%AC%E3%83%B3%E3%81%A8%E3%83%91%E3%83%B3%E3%82%BF%E3%82%B0%E3%83%A9%E3%83%95",
        "aria-label": "キーボード方式の基本２種類、メンブレンとパンタグラフ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キーボード方式の基本２種類、メンブレンとパンタグラフ`}</h2>
    <p>{`キーボードには複数の内部構造が存在し、それによって打感が変わってくる。そのうち最も多くの人が使っているであろうメンブレンとパンタグラフをまずは抑えよう。`}</p>
    <h3 {...{
      "id": "メンブレンキーボード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%B3%E3%83%96%E3%83%AC%E3%83%B3%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89",
        "aria-label": "メンブレンキーボード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メンブレンキーボード`}</h3>
    <Image {...props} name="membrane" alt="メンブレンキーボード方式" mdxType="Image" />
    <p>{`デスクトップパソコンを購入すると大抵付属してくるのがこの種類である。キーの表面の下はラバードーム、すなわちゴム素材でできており、押すと他のキーボードと比べて少しぐにょっとした感触がある。
キーストローク(キーが反応するまでの押し込みの距離)が長く、しっかりとしたタッチ感を得ることができる。またこれにより、押し間違えの予防にもなる。
製造コストも安く、2000円程度で購入できる製品が多い。`}</p>
    <AccentBox title="メンブレンの評判" type="check" mdxType="AccentBox">
  <li>力加減や打鍵音がちょうどよい</li>
  <li>高いモデルでも結局壊れるので、そこそこ使い勝手の良い安価なモデルを繰り返し使っている</li>
  <li>押した感がある</li>
  <li className="bad">ストロークが長い感じがして少し入力時間がかかる感じ</li>
  <li className="bad">ぐにゃっとした感覚があり、打鍵感が少し気持ち悪い</li>
    </AccentBox>
    <p>{`メンブレンに関しては使い慣れていて安価、それでいてそれほど不自由はしないので十分という意見や、パンタグラフと比べて押した感があって良いなどの意見があった。一方でゴムを使っているために感触がぐにゃっとして爽快感に欠けるという意見もちらほら。`}</p>
    <p>{`安価で壊れても気楽に買い換えられる、使い慣れているという理由で使い続ける人が多いようである。`}</p>
    <h3 {...{
      "id": "パンタグラフキーボード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%83%B3%E3%82%BF%E3%82%B0%E3%83%A9%E3%83%95%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89",
        "aria-label": "パンタグラフキーボード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パンタグラフキーボード`}</h3>
    <Image {...props} name="pantagraph" alt="メンブレンキーボード方式" mdxType="Image" />
    <p>{`ノートパソコンにはまず搭載されている平べったいキーボードをパンタグラフと呼ぶ。電車のパンタグラフのような内部構造をしていることから名づけられた。英語でパンタグラフの意味はひし形。`}</p>
    <p><strong parentName="p">{`キーストロークが短く少し力を加えただけでもキー入力を行うことができる`}</strong>{`というメリットがある一方、寸止めが効かないため誤入力が多い、打っている感じがしないという意見も多い。`}</p>
    <AccentBox title="パンタグラフの評判" type="check" mdxType="AccentBox">
  <li>薄くて持ち運びやすい。指へのひっかかりが少ない</li>
  <li>柔らかい打ち心地</li>
  <li>ストロークが短く、軽く打てる。またカチカチという音も好き</li>
  <li>見た目がスタイリッシュでかっこよい</li>
  <li className="bad">キーストロークが浅く入力できているのか不安になる</li>
  <li className="bad">脆弱そうで頼りない。叩いている感じがしない</li>
  <li className="bad">クリック感がイマイチでプログラミングでのコーディングスピードがイマイチ乗らない</li>
  <li className="bad">柔らかめの感触でふにゃふにゃした感じがする</li>
    </AccentBox>
    <p>{`アンケートでは好きと答えた人も嫌いと答えた人も多かった。`}<strong parentName="p">{`軽く打てる半面フィードバックが得られにくいのがパンタグラフの特徴`}</strong>{`である。カチカチという音は、キーストロークが短く、強くはじくとプラスチックがぶつかる音がなるという意味であり、これは嫌いな人もいた。`}</p>
    <p>{`他には外見がゴツゴツしておらず、`}<strong parentName="p">{`スタイリッシュでカッコいいモデルが多い`}</strong>{`というのはなるほどという感じである。`}</p>
    <p>{`国内大手メーカーの多くはデスクトップパソコン用のキーボードにパンタグラフを採用しているが、これはインテリアに馴染むという理由からであろう。`}</p>
    <h2 {...{
      "id": "メカニカルキーボード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%82%AB%E3%83%8B%E3%82%AB%E3%83%AB%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89",
        "aria-label": "メカニカルキーボード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メカニカルキーボード`}</h2>
    <p>{`メンブレンとパンタグラフが最も一般的なキーボードであるが、`}<strong parentName="p">{`より良い打鍵感を追求した高級キーボードとしてメカニカルキーボードがある。`}</strong></p>
    <p>{`キーストロークが長いものが多く外見はメンブレンと変わらないが、内部構造としてラバーではなく金属製のスプリングを使っている。このため`}<strong parentName="p">{`メンブレンのようなグニョっとした感じがなく爽快な打ち心地が楽しめる`}</strong>{`。`}</p>
    <p>{`また、キースイッチの一つが壊れても取り替え用のスイッチが売られているためメンテナンス性が高いという特徴もある。`}</p>
    <p>{`メカニカルキーボードは主にドイツのCHERRY社によって製造されており、そのキースイッチをCHERRY MXスイッチと呼んでいる。CHERRY MXスイッチにはスプリングの性質などで打鍵感に違いがあり、色によって茶軸・赤軸・青軸・黒軸・桃軸というように種類わけされている。`}</p>
    <p>{`ここでは`}<strong parentName="p">{`代表的な茶軸・赤軸・青軸`}</strong>{`についてそれぞれ解説していく。キースイッチとしてはCHERRY MXだけではなく、他のメーカーが制作したメカニカルキーボードも多いのだが、この代表的な３つを抑えておくことで、茶軸に近い感じといったレビューも感覚が分かるようになる。`}</p>
    <h3 {...{
      "id": "茶軸",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%8C%B6%E8%BB%B8",
        "aria-label": "茶軸 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`茶軸`}</h3>
    <Image {...props} name="brown_s" alt="茶軸キースイッチ" mdxType="Image" />
    <p>{`メンブレンから移行する場合、`}<strong parentName="p">{`もっとも違和感を感じづらく、快適な打鍵感が得られる`}</strong>{`オーソドックスなメカニカルスイッチが茶軸である。`}</p>
    <p>{`初心者向けにおすすめと多くの記事に書かれている事が多いが、別にキーボードを押すのに初心者も上級者もない。ただメンブレンとの違和感が少ないため`}<strong parentName="p">{`購入して失敗する可能性が低いスイッチ`}</strong>{`と言える。`}</p>
    <p>{`音はメンブレンと比べて気持ち大きい程度で、静かなオフィスでもない限り仕事で問題とはならない。管理人が実際職場で使っているのも茶軸に近い打ち心地のものである。少なくともうるさいと文句を言われたことはない。`}</p>
    <AccentBox title="茶軸キーボードの評判" type="check" mdxType="AccentBox">
  <li>押し感がちょうどよい、タイピング音も良い</li>
  <li>ちょうどよいクリック感、疲労感とミス数が違う</li>
  <li>メカニカルキーボードの「打ってる感」が好みです。むしろ打鍵時の音がないと不安になる</li>
  <li>打っていて気持ちいいし、音もそれほど気にならない</li>
  <li className="bad">音がややでかい</li>
  <li className="bad">押した後の反発力が青軸と比べると弱い</li>
    </AccentBox>
    <p>{`アンケートでも概ね好評で、`}<strong parentName="p">{`使う場所を選ばず、最も不満が少ないキーボード`}</strong>{`という結果であった。`}</p>
    <h3 {...{
      "id": "赤軸",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%B5%A4%E8%BB%B8",
        "aria-label": "赤軸 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`赤軸`}</h3>
    <Image {...props} name="red_s" alt="赤軸キースイッチ" mdxType="Image" />
    <p>{`茶軸やメンブレンと比べて`}<strong parentName="p">{`軽いタッチでキーボードを叩くことができるメカニカルスイッチ`}</strong>{`が赤軸である。`}</p>
    <p>{`キーの押し下げ量に応じて重くなるものの、変化が線形であるためフィードバック感があまり感じらず、押した感を求める人にはあまり向かない。しかし`}<strong parentName="p">{`少ない力で楽にタイピングができるため長時間の文字入力に向いている`}</strong>{`とされる。`}</p>
    <p>{`また、`}<strong parentName="p">{`音が非常に静か`}</strong>{`なことも特徴である。このため静かなオフィスでの使用には向いている。他人に文句を言われることはまずないであろう。`}</p>
    <p>{`感触としては表現が難しいがスコスコとした感じである。`}</p>
    <AccentBox title="赤軸キーボードの評判" type="check" mdxType="AccentBox">
  <li>音が静か、会社で迷惑を掛けない</li>
  <li>キータッチが滑らかな感触で音が静かだからです</li>
  <li>なじむ</li>
  <li>キーが軽くて疲れづらい</li>
  <li className="bad">クリック感が頼りない</li>
  <li className="bad">誤入力が増える</li>
  <li className="bad">自分には柔らかすぎる気がする</li>
    </AccentBox>
    <p>{`赤軸はパンタグラフに似た傾向がアンケートから見て取れた。好きなキーボード方式として挙げる人は多かったものの、キーが軽すぎて誤入力が増えただとか、クリックした感じが無く不満という意見も多く、賛否両論であった。`}</p>
    <p>{`軽く打てるという観点ではパンタグラフと同じで、それに加えて`}<strong parentName="p">{`キーストロークの深さや更なる静音性を望む人に向いている`}</strong>{`と思われる。`}</p>
    <h3 {...{
      "id": "青軸",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9D%92%E8%BB%B8",
        "aria-label": "青軸 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`青軸`}</h3>
    <Image {...props} name="blue_s" alt="青軸キースイッチ" mdxType="Image" />
    <p>{`キーを押すたびにカチッカチッと大きめの音が鳴り、重さもそれなりにあるため`}<strong parentName="p">{`明確なクリック感が得られるメカニカルスイッチが青軸`}</strong>{`である。`}</p>
    <p>{`音が心地よく、`}<strong parentName="p">{`爽快感があるため癖になる人も多い`}</strong>{`。一方で音が大きいのでオフィスや人のいるところでは文句を言われる可能性が高い。`}</p>
    <p>{`ゲーム中はもともとBGMなどの音が流れているので音の大きさが気にならないのと、クリック感があることにより誤入力を防止しやすいため`}<strong parentName="p">{`PCゲームユーザーには非常に人気が高い`}</strong>{`。`}</p>
    <AccentBox title="青軸キーボードの評判" type="check" mdxType="AccentBox">
  <li>音が気持ちいい、爽快</li>
  <li>カチっとした打鍵感。打っている感覚がある</li>
  <li>キーを打った時の打鍵音とカチャと指先に響く振動がたまらなく好き</li>
  <li>リズムに乗る感じ</li>
  <li className="bad">打鍵音がうるさく感じてしまう</li>
  <li className="bad">青軸はちょっと大変すぎますね。もっと軽さも欲しいところです</li>
  <li className="bad">キーが重過ぎる。タイプに力が必要な感覚があり、触っていて疲れる</li>
    </AccentBox>
    <p>{`兎に角うるさいという意見が最も多かった。ただし打鍵感が最も気持ちよいのは確かなので、ゲーマーやプログラマーなど、`}<strong parentName="p">{`そこまで文章を書く人ではなく、周りに人のいない環境で使えるならば非常におすすめ`}</strong>{`できる。`}</p>
    <h2 {...{
      "id": "静電容量無接点方式",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E9%9D%99%E9%9B%BB%E5%AE%B9%E9%87%8F%E7%84%A1%E6%8E%A5%E7%82%B9%E6%96%B9%E5%BC%8F",
        "aria-label": "静電容量無接点方式 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`静電容量無接点方式`}</h2>
    <Image {...props} name="musetti" alt="東プレのRealForce" link="https://amzn.to/3dN4jfE" mdxType="Image" />
    <p>{`最後に静電容量無接点方式を紹介する。通常のキーボードはキーが押された時に金属同士がつながり、電流が流れることでキーが押されたと認識するが、この方式では`}<strong parentName="p">{`スプリングがコイルの役割を果たし、キーが押されたときに電流が発生することで押されたことをコンピュータが認識する`}</strong>{`。`}</p>
    <p>{`キーがこすれて摩耗することがない(=無接点方式)ため、`}<strong parentName="p">{`耐久性が非常に高く`}</strong>{`、文書入力の機会が多い人に主に愛用されている。メーカーとしては東プレのRealForceが有名である。`}</p>
    <p>{`感触としては赤軸に近いが、赤軸と比べるとわずかに反発があり、打ち心地はスコスコと控えめな音がなる。フェザータッチとも言われ軽いタッチで入力できるものが多い。また、赤軸と同様`}<strong parentName="p">{`静音性も非常に高い`}</strong>{`。`}</p>
    <AccentBox title="静電容量無接点の評判" type="check" mdxType="AccentBox">
  <li>キーを叩いた時の感触が一番好きです。ちょっと抵抗感があって柔らかすぎない</li>
  <li>滑らかな打鍵感と心地よい打鍵音が好きです</li>
  <li>荷重が小さいものが多いため、力を入れずに打鍵できる</li>
  <li className="bad">キーを打った時の感触がなんとなく気になる。</li>
  <li className="bad">コストが掛かっているのでキーボードそのものが高くなるから</li>
    </AccentBox>
    <p>{`値段が高いだけあり、それほど使用している人はいなかった。またややキーを叩いた時の感触が独特であるため敬遠する人もいる。`}</p>
    <p>{`賛否もやや別れる感触のため、`}<strong parentName="p">{`購入する時に試せる場所があれば一度は試しておくことをおすすめする`}</strong>{`。`}</p>
    <h2 {...{
      "id": "好きなキーボード方式、嫌いなキーボード方式アンケート結果",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%A5%BD%E3%81%8D%E3%81%AA%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E6%96%B9%E5%BC%8F%E3%80%81%E5%AB%8C%E3%81%84%E3%81%AA%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E6%96%B9%E5%BC%8F%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88%E7%B5%90%E6%9E%9C",
        "aria-label": "好きなキーボード方式、嫌いなキーボード方式アンケート結果 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`好きなキーボード方式、嫌いなキーボード方式アンケート結果`}</h2>
    <p>{`最後にキーボード方式のまとめに入る。今回アンケートを50人に取り、好き、嫌いをそれぞれ一つずつ選択してもらったところ次のようになった。`}</p>
    <Image {...props} name="keyboard_ranking" alt="キーボード方式のランキング" mdxType="Image" />
    <p>{`パンタグラフは使い慣れている人が多いのと、軽いタッチが人気で好きな人が多かったが、打っている感がないとの意見が多く嫌いな人も多かった。`}</p>
    <p>{`赤軸は静音性と滑らかな打ち心地が評価されたが、やっぱり自分には軽すぎて合わないという意見も多かった。ともあれ`}<strong parentName="p">{`好きから嫌いを引いた数ではNo1`}</strong>{`で穏やかな気持ちで使うことができそうである。`}</p>
    <p>{`青軸は打鍵感が爽快で気持ちがいいと評価されたが、うるさくて自分も周りも集中できないとの反対意見が多く、圧倒的に嫌いな人が多かった。ただし、`}<strong parentName="p">{`ゲーマーやプログラマーの評価ではNo1の評価`}</strong>{`を得ていた。`}</p>
    <p>{`茶軸は嫌いな人が少なく、`}<strong parentName="p">{`快適な打ち心地が評価され、バランス感の良い結果`}</strong>{`となった。嫌いな意見でも赤軸と比べるとうるさいなどで、全てのキーボードを使ったことのある人に限定したアンケートならば嫌いなキーボードへの表は入らなかったであろう。`}</p>
    <p>{`メンブレンは`}<strong parentName="p">{`使い慣れやコスパで十分`}</strong>{`という意見がある一方で、ぐにゃっとした打感がなじめないという意見もあり、総じて評判は悪かった。`}</p>
    <p>{`最後に静電容量無接点方式は、コアなファンがいる一方でそれほど爽快感があるわけではなく、`}<strong parentName="p">{`コスパを考えるとイマイチ`}</strong>{`という感じであまり良くない結果となった。`}</p>
    <h2 {...{
      "id": "テンキーは必要か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%86%E3%83%B3%E3%82%AD%E3%83%BC%E3%81%AF%E5%BF%85%E8%A6%81%E3%81%8B%EF%BC%9F",
        "aria-label": "テンキーは必要か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`テンキーは必要か？`}</h2>
    <Image {...props} name="tenkey" alt="テンキー" mdxType="Image" />
    <p>{`キーボードの右側についているテンキー。0から9まで10(テン)個のキーがあることからそう呼ばれており、主に数値入力を行うために使われるキーである。`}</p>
    <p>{`使い慣れると便利なキーであるが、一方でキーボードが大きくなり場所を食う。使わないならば省いていきたいところ。`}</p>
    <h3 {...{
      "id": "Excelの数値入力や電話番号入力で高い使用頻度",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Excel%E3%81%AE%E6%95%B0%E5%80%A4%E5%85%A5%E5%8A%9B%E3%82%84%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%E5%85%A5%E5%8A%9B%E3%81%A7%E9%AB%98%E3%81%84%E4%BD%BF%E7%94%A8%E9%A0%BB%E5%BA%A6",
        "aria-label": "Excelの数値入力や電話番号入力で高い使用頻度 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Excelの数値入力や電話番号入力で高い使用頻度`}</h3>
    <p>{`テンキーは慣れるまでやや時間がかかるが、`}<strong parentName="p">{`慣れてしまえば絶対にテンキ―が便利で入力が速い`}</strong>{`。テンキー無しの数値入力はブラインドタッチがやりにくい位置にあるほか、半角、全角を切り替えを最初に行うのも面倒である。`}</p>
    <p>{`使用する目的としてはExcelで家計簿をつける、電話番号や各種認証番号を入力する場合が多いが、`}<strong parentName="p">{`理系大学で実験の数値を入力する、3Dモデリングソフトで座標値やRGB値を入力するなど意外と数値を入力するケースは多い`}</strong>{`。`}</p>
    <p>{`実際にアンケートでは半数以上の人がテンキーを使うと答えており、私が想像していたよりも多い結果となった。`}</p>
    <h3 {...{
      "id": "あまり数値入力をしない人は削ると良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%82%E3%81%BE%E3%82%8A%E6%95%B0%E5%80%A4%E5%85%A5%E5%8A%9B%E3%82%92%E3%81%97%E3%81%AA%E3%81%84%E4%BA%BA%E3%81%AF%E5%89%8A%E3%82%8B%E3%81%A8%E8%89%AF%E3%81%84",
        "aria-label": "あまり数値入力をしない人は削ると良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`あまり数値入力をしない人は削ると良い`}</h3>
    <p>{`しかし、テンキーの使用機会がそれほど無いと分かった場合は削った方が良い。私はソフトウェアエンジニアだが、プログラミングでまとまって数値を入力することはあまりないし、Excelを使用することも滅多にないためテンキーは付けていない。`}</p>
    <p>{`その分`}<strong parentName="p">{`机の空間が広く使えるようになり、キーボードとマウスを行き来する時のオーバーヘッドの時間も抑えることができる`}</strong>{`。これにより生産性のアップにつながる。`}</p>
    <h3 {...{
      "id": "不安ならつけておけ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%B8%8D%E5%AE%89%E3%81%AA%E3%82%89%E3%81%A4%E3%81%91%E3%81%A6%E3%81%8A%E3%81%91",
        "aria-label": "不安ならつけておけ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`不安ならつけておけ`}</h3>
    <p>{`まだパソコンの使用目的があまり定まっておらず、`}<strong parentName="p">{`使うかどうか不安な状況ならば一先ずつけておく事をおすすめ`}</strong>{`する。`}</p>
    <p>{`場所を取るといってもそこまでクリティカルに占有する訳ではない。また、デスクトップパソコンでテンキーに慣れた後、テンキーの付かないノートパソコンは苦痛と思うかもしれない。しかしそんな場合に備えて外付けのテンキーも販売されているためあまり問題とはならないだろう。`}</p>
    <p>{`まずは付けてみて、それでもやっぱり使用頻度が低いなと思った場合は削ると良いだろう。`}</p>
    <h3 {...{
      "id": "ショートカット割り当てをしてみるのもあり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B7%E3%83%A7%E3%83%BC%E3%83%88%E3%82%AB%E3%83%83%E3%83%88%E5%89%B2%E3%82%8A%E5%BD%93%E3%81%A6%E3%82%92%E3%81%97%E3%81%A6%E3%81%BF%E3%82%8B%E3%81%AE%E3%82%82%E3%81%82%E3%82%8A",
        "aria-label": "ショートカット割り当てをしてみるのもあり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ショートカット割り当てをしてみるのもあり`}</h3>
    <p>{`番外として、数値入力であまり使われず無駄になっている場合、有効活用するためにショートカットを割り当てるのは面白いかもしれない。`}</p>
    <p><a parentName="p" {...{
        "href": "http://ahk.wktk.so/download/download/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`DressKey`}</a>{`というソフトを使えば簡単にキーが割り当てられ、テンキーを押すだけでスリープやロック、文字の拡大・縮小、検索ボックスにフォーカスを当てるなど様々なアクションを割り当てることができる。気になったなら試してみよう。`}</p>
    <h2 {...{
      "id": "無線か有線どちらが良いか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%84%A1%E7%B7%9A%E3%81%8B%E6%9C%89%E7%B7%9A%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E8%89%AF%E3%81%84%E3%81%8B",
        "aria-label": "無線か有線どちらが良いか permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`無線か有線どちらが良いか?`}</h2>
    <p>{`電波の接続状況が悪い、あるいはキーボードの電池が十分で無い場合にタイピングを行うと、遅延して文字入力が起こる、反応しない、あるいはチャタリングと呼ばれる文字が勝手に連続して入力される現象が発生することがある。`}</p>
    <p>{`高級なキーボードは電波感度が高いと思われがちだが、同様に問題が起こる可能性はある。`}</p>
    <p>{`また`}<strong parentName="p">{`電池の交換も以外に面倒`}</strong>{`である。キーボードの電池は毎日使用していても１年は持ち滅多に切れることは無いが、切れにくいからこそ油断して必要となった時に無い可能性も高い。`}</p>
    <p>{`有線だとこれらの問題が起こることが無いため安心である。ただし、やはりケーブルだと見た目がスッキリとしないため視覚的なストレスが溜まることもある。どちらが良いかは非常に判断に難しいが、特に`}<strong parentName="p">{`ケーブルの取り回しが気にならなければ安定性の観点から有線をおすすめ`}</strong>{`する。`}</p>
    <p>{`無線も大抵は問題はないが、アンケートでは３名から失敗したキーボードの要因として無線がうまくつながらなかったとの報告を受けている。`}</p>
    <h3 {...{
      "id": "無線ならマウスとキーボードのセットが良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%84%A1%E7%B7%9A%E3%81%AA%E3%82%89%E3%83%9E%E3%82%A6%E3%82%B9%E3%81%A8%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%81%AE%E3%82%BB%E3%83%83%E3%83%88%E3%81%8C%E8%89%AF%E3%81%84",
        "aria-label": "無線ならマウスとキーボードのセットが良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`無線ならマウスとキーボードのセットが良い`}</h3>
    <p>{`無線キーボードは通信するために必要なUSB子機をパソコン本体のUSBにセットして使うのが一般的である。マウスも無線だと、それぞれ１個ずつ、合計２つUSB子機が必要となるが、`}<strong parentName="p">{`同じメーカーの製品だと一つで済むことが多い`}</strong>{`。`}</p>
    <p>{`必要なUSB端子の数が減ることがメリットだが、`}<strong parentName="p">{`最大のメリットは無線同士の干渉が減り、動作が安定すること`}</strong>{`である。`}</p>
    <p>{`私の家のケースではUSB子機２つまでなら問題がなかったが、もう一つBluetooth経由で音楽を聴こうと思い端子を増やしたらマウスの反応が極端に悪くなってしまった。(キーボードは問題が無かった)`}</p>
    <p>{`気に入ったものが無ければ仕方がないが、合わせられるならば合わせておいた方が無難である。`}</p>
    <h2 {...{
      "id": "エルゴノミクスキーボード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A8%E3%83%AB%E3%82%B4%E3%83%8E%E3%83%9F%E3%82%AF%E3%82%B9%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89",
        "aria-label": "エルゴノミクスキーボード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`エルゴノミクスキーボード`}</h2>
    <Image {...props} name="elgonomic" alt="Microsoft Sculpt Ergonomic Keyboard" link="https://amzn.to/31e1aLU" linkText="amazon:Microsoft Ergonomic Keyboard" mdxType="Image" />
    <p>{`エルゴノミクスとは人間が自然な動きや体勢でいられ、無理な負荷を体にかけないための理論で、これに基づいて設計されたキーボードを使うと、可能な限り自然な体勢でキー入力を行うことができる。`}</p>
    <p>{`上の写真のようにキーボードが左右に分かれ、ハの字をしていることが分かる。ハの字の体勢を取るとわかると思うが、肩回りの筋肉に無理な力が入らない。`}</p>
    <p>{`これにより手首や腕の位置が自然体となり、体への負荷が減少し、特に`}<strong parentName="p">{`肩こりに対しては効果が大きい`}</strong>{`。`}</p>
    <p>{`バリエーションが少なくキーストロークや打鍵感はあまり好みのものが選べるかは分からないが、長時間作業を行い、体への負荷軽減を重視するならば生産性向上に大きく寄与してくる。`}</p>
    <p>{`形状を見るとわかるが、`}<strong parentName="p">{`１週間程度は慣れが必要`}</strong>{`で、使用した最初はタイプミスを連発するといったこともあると思われる。また左右で分離された構造になっているので`}<strong parentName="p">{`ブラインドタッチが正しくできない人は更につらい思いをする`}</strong>{`ことになる。`}</p>
    <p>{`管理人の場合は数字の６を右手側で打っていたが、左手側に配置されているため最初非常にストレスを感じてしまった。正しいブラインドタッチに強制してくれるという観点ではありがたいのだが。`}</p>
    <Image {...props} name="separate" alt="左右分離型キーボード" mdxType="Image" />
    <p>{`さらに進んだ例では分離型キーボードというものも存在する。マウスの置き場所には悩むが、肩を大きく開くことができ非常に快適である。ただし日本語キーボードが発売されているのは見たことが無いため玄人向きではあるが。`}</p>
    <p>{`やや癖の強いキーボードは多いものの、肩こりが酷い、目が痛いなど健康が心配される場合は導入を検討してみると良いだろう。きっと楽になるはずである。`}</p>
    <h2 {...{
      "id": "その他キーボード選びで重視する項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E9%81%B8%E3%81%B3%E3%81%A7%E9%87%8D%E8%A6%96%E3%81%99%E3%82%8B%E9%A0%85%E7%9B%AE",
        "aria-label": "その他キーボード選びで重視する項目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他キーボード選びで重視する項目`}</h2>
    <p>{`キーボード方式、テンキー、有線・無線、エルゴノミクスと説明してきたが、購入時に注意をしなければならないポイントや知っておくべき用語を整理しておく。`}</p>
    <h3 {...{
      "id": "Nキーロールオーバー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#N%E3%82%AD%E3%83%BC%E3%83%AD%E3%83%BC%E3%83%AB%E3%82%AA%E3%83%BC%E3%83%90%E3%83%BC",
        "aria-label": "Nキーロールオーバー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nキーロールオーバー`}</h3>
    <p><strong parentName="p">{`PCゲームを行うならば必須の機能`}</strong>{`。一度に複数のキーを押して反応するかという指標。一般的な安物キーボードは3キーロールオーバー程度で、同時に４つのボタンを押すことができない。`}</p>
    <p>{`たとえば、斜め移動しつつジャンプしながら攻撃する場合には同時ボタン４つ押しが必要だが、その動作が行えない。これは致命的である。`}</p>
    <p>{`FPSなどのゲームで遊ぶ予定がある人は複数キーロールオーバーに対応していることを確認しよう。`}</p>
    <h3 {...{
      "id": "キーピッチ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%BC%E3%83%94%E3%83%83%E3%83%81",
        "aria-label": "キーピッチ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キーピッチ`}</h3>
    <Image {...props} name="keypitch_s" alt="キーピッチ" mdxType="Image" />
    <p>{`キーピッチとはキーとキーの間の長さのことで、一般的なデスクトップパソコンに付属するキーボードならば19mm、小型のノートパソコンならば16mmといったところである。`}</p>
    <p><strong parentName="p">{`キーの間隔が狭いとそれだけで打ちづらく、また体勢も縮こまった状態になってしまうために長時間作業を行うと疲れがたまりやすい`}</strong>{`。ただし手が小さい人の場合キーピッチが狭い方が取り回しがしやすく速く打てるなど個人差はある。`}</p>
    <p>{`しかし19mmがデスクトップの主流であり、他の選択肢があまりないのが現状であるからそれほど意識する必要は無いといえる。`}</p>
    <h3 {...{
      "id": "キーストローク",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%BC%E3%82%B9%E3%83%88%E3%83%AD%E3%83%BC%E3%82%AF",
        "aria-label": "キーストローク permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キーストローク`}</h3>
    <p><strong parentName="p">{`キーストロークとはキーを押し下げた時の深さ`}</strong>{`のことであり、標準のもので3mm~4mm、浅いもので2mm程度である。パンタグラフが一様に浅いが、他の方式では製品によってまちまちである。`}</p>
    <p>{`一般的にキーストロークが浅いと少し押しただけでもボタンが反応し、速くタイピングができるというメリットがある。逆に深いと打った感があり、タイプミスが少なくなる。`}</p>
    <p>{`ただ、打鍵感や打ち間違えの多さには慣れも大きく影響するため、パンタグラフだからといって打ち間違えが必ずしも増えるわけではないし、キーストロークが深いからといってタイプ速度が遅くなるわけではない。`}</p>
    <h3 {...{
      "id": "ライトエフェクト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A9%E3%82%A4%E3%83%88%E3%82%A8%E3%83%95%E3%82%A7%E3%82%AF%E3%83%88",
        "aria-label": "ライトエフェクト permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ライトエフェクト`}</h3>
    <Image {...props} name="keyboard_light_s" alt="ライトエフェクト" mdxType="Image" />
    <p>{`特にゲーム用として光るキーボードが多く発売されているが、ゲーム用途に限らず光らせることでパソコン作業の気分を盛り上げてくれる一面がある。`}</p>
    <p>{`また製品によっては各キーごとに光らせる色を固定することで、キーの位置を把握しやすくするなど多彩なカスタマイズを楽しむこともできる。`}</p>
    <h3 {...{
      "id": "キーボード配列",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E9%85%8D%E5%88%97",
        "aria-label": "キーボード配列 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キーボード配列`}</h3>
    <p>{`日本で販売されているキーボード配列は大きく分けて２種類あり、一つは一般的にパソコンを購入したら標準で付属しているJIS配列(日本語配列)というもの、そしてもう一つがアメリカでスタンダードなUS配列なるものである。`}</p>
    <p>{`アルファベットや数字入力など大きな点で変わりは無いが、セミコロンの位置やダッシュ、アットマークなど記号の位置の多くの表記が異なっている。`}</p>
    <p>{`キーとボタンのマッピングを決めるのはWindowsの設定であるため、Windowsで日本語の標準キーボードを使うよう設定されている時にUSキーボードを使うと、USキーボードの上に印字されている印字と異なる文字が出力されてしまい混乱を招く。`}</p>
    <p><strong parentName="p">{`英語やプログラムが打ちやすいとの理由でUS配列を好む人もいるが、自信がない場合は標準のJIS配列のキーボードをおとなしく使おう`}</strong>{`。`}</p>
    <p>{`日本語配列を更に分類すると、左右にWindowsキーがある109配列、左側だけにWindowsキーがある108配列が現在の標準である。あまりこだわる人は見たことがないが、用語は知っておくと良いだろう。`}</p>
    <h3 {...{
      "id": "変荷重",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%89%E8%8D%B7%E9%87%8D",
        "aria-label": "変荷重 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`変荷重`}</h3>
    <p>{`キーを押すときの重さは一般的には45グラム程度であるが、全てのキーが同じ重さだと、力が入りにくい小指で押すキーがやや押しづらさを感じる。個人としてはpとEnterは押すのが苦手である。`}</p>
    <p>{`この問題を解決しているのが変荷重キーボードで、`}<strong parentName="p">{`キーごとに重みを押しやすいよう調整してくれている`}</strong>{`。小指のフィーリングに違和感を抱えるならば試しても良い選択肢である。`}</p>
    <h3 {...{
      "id": "その他アンケートから分かった重視項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88%E3%81%8B%E3%82%89%E5%88%86%E3%81%8B%E3%81%A3%E3%81%9F%E9%87%8D%E8%A6%96%E9%A0%85%E7%9B%AE",
        "aria-label": "その他アンケートから分かった重視項目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他アンケートから分かった重視項目`}</h3>
    <p>{`最後にアンケートから重視しているポイントは自由に回答してもらったが、なるほどな、と思ったものを列挙しておく。`}</p>
    <AccentBox title="その他重視項目" type="check" mdxType="AccentBox">
  <li>ほこりの溜まりにくいキーボードデザイン</li>
  <li>キーボードの裏側に稼働するスタンドがある場合、ここの耐久性をチェックするようにしています。過去に、この箇所がよく破損するキーボードを所有していたので</li>
  <li>Bluetooth接続の有無</li>
  <li>スタイリッシュさ、可愛さ、ごつごつしてるものは選ばない</li>
  <li>エンターキーの押しやすさ</li>
  <li>シリコンキーボードは実用レベルとは言えず使えない</li>
    </AccentBox>
    <p>{`ほこりは確かに気になる。知らずのうちにキースイッチ間の溝に溜まっていくのである。エアスプレーやエアダスターと呼ばれるキーボード用の掃除機で取ることもできるが、そもそも入りにくいかどうかを店舗で触れる場合は検証してみると良い。`}</p>
    <p>{`キーボードの裏には角度をつけるためのスタンドがついている場合が多い。高級なキーボードだと多段階で調整できる機種も存在する。しかし安物は不安定なことが多く、強くはじく癖がある人は気を配ってみると良い。`}</p>
    <p>{`Bluetoothの有無は、主にスマホやタブレットで使う時に選択肢となるものである。`}<strong parentName="p">{`多くの無線はBluetooth方式ではなく、タブレットとは接続できない`}</strong>{`ため無線の中でもBluetooth方式に対応しているものを選ぶ必要がある。`}</p>
    <p><strong parentName="p">{`エンターキーは見落とされがちなポイント`}</strong>{`である。コンパクトなキーボードの中には非常にエンターキーが小さいものがあり、ストレスを感じる原因となる。本当にブラインドタッチで正しくエンターを叩けるかを自問自答すべし。`}</p>
    <p>{`持ち運びできるキーボードとしてシリコンキーボードがあるが、ぐにょぐにょしていて非常に使いづらく、実用レベルではないという意見があった。確かにそう思うのでおすすめはできない。`}</p>
    <h2 {...{
      "id": "おすすめのキーボード紹介",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E7%B4%B9%E4%BB%8B",
        "aria-label": "おすすめのキーボード紹介 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめのキーボード紹介`}</h2>
    <p>{`最後にいくつかキーボードをおすすめしておく。何度も言っているように、またアンケート結果からも明らかなようにキーボードのこだわりは人それぞれのため万人におすすめのキーボードなどは存在しない。`}</p>
    <p>{`そのため細分化しすぎるとおすすめ25選みたいなバカげたことになってしまうわけである。ただコスパが良かったり品質が高かったりするキーボードは存在するのでいくつか本格的なキーボードを紹介しておく。安物キーボードは失敗したら買い替えれば良いのでここでは紹介しない。`}</p>
    <h3 {...{
      "id": "FILCO-Majestouch2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#FILCO-Majestouch2",
        "aria-label": "FILCO Majestouch2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FILCO Majestouch2`}</h3>
    <Image {...props} name="filco" alt="FILCO Majestouch2" mdxType="Image" />
    <p>{`本格的なメカニカルスイッチキーボードとしておすすめしたいのがFILCOのMajestouch2である。ダイヤテックという日本の会社の製品である。`}</p>
    <p>{`メカニカルスイッチの爽快感もさることながら、ずっしりとした重みがあるため、位置がずれることなく安定してタイピングを行うことができる。赤、茶、青等軸は選べるため、この記事を見て自分に適していそうな色を選ぼう。 `}</p>
    <Product alink="https://amzn.to/3uzcHVS" rlink="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2FFILCO%2BMajestouch2%2F&m=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2FFILCO%2BMajestouch2%2F" mdxType="Product" />
    <h3 {...{
      "id": "Logicool-G-PRO-X-ゲーミングキーボード-テンキーレス-G-PKB-002",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Logicool-G-PRO-X-%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89-%E3%83%86%E3%83%B3%E3%82%AD%E3%83%BC%E3%83%AC%E3%82%B9-G-PKB-002",
        "aria-label": "Logicool G PRO X ゲーミングキーボード テンキーレス G PKB 002 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logicool G PRO X ゲーミングキーボード テンキーレス G-PKB-002`}</h3>
    <Image {...props} name="logicool" alt="Logicool G PRO X" mdxType="Image" />
PRO X PRO X 
私が会社で使用しているキーボードである。タクタイルは茶軸に近い感覚でいてキーストロークは短めの3mmで素早く入力ができる。快適性とキー入力の速度を両立させたキーボードである。それでいて音がうるさくない。
    <p>{`ちなみにゲーミングキーボードであり、ボタンを押すとイルミネーションが綺麗だが、会社で光らすわけにもいかないので使った事は無い。`}</p>
    <p>{`単体ではクリッキーという青軸に近い音のものしかないため、タクタイルは別途購入しなければならない点が非常に難点だが、テンキーレスで気に入ったものがないためそこは渋々。`}</p>
    <Product alink="https://amzn.to/2NHze2a" rlink="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2FG-PKB-002%2F" mdxType="Product" />
    <h3 {...{
      "id": "東プレ-REALFORCE",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9D%B1%E3%83%97%E3%83%AC-REALFORCE",
        "aria-label": "東プレ REALFORCE permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`東プレ REALFORCE`}</h3>
    <Image {...props} name="musetti" alt="東プレのRealForce" mdxType="Image" />
    <p>{`静電容量無接点方式を採用した東プレのRealForce、茶軸派の私にはあまり合わなかったが、好きなキーボードとして挙げている人も多く、特に赤軸系の軽い力で打ちたい人にとっては人気のある製品である。`}</p>
    <p>{`ゲームをするにあたって重要なNキーロールオーバーにも対応し、また静音性が非常に高く、普段使いにも長けたキーボードであることから万能な一台と言える。`}</p>
    <Product alink="https://amzn.to/37Monet" rlink="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2Frealforce%2F" mdxType="Product" />
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`以上、キーボードの選び方を解説し、おすすめ商品をいくつか紹介した。自分に合ったキーボードのイメージは掴めただろうか？`}</p>
    <p>{`大抵の場合付属のキーボードで十分と満足してしまいがちであるが、本物のキーボードを知ってしまうともう後には戻れない怖さがある。`}</p>
    <p>{`しかし、それにより快適なPC生活が待っていることは間違いない。是非最適なキーボードを見つけて欲しい。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      